import Container from './Container'
import { Link } from 'react-scroll'
const Header = () => {
  return (
    <header className="py-4 text-white">
      <Container className="hidden md:flex md:items-center md:justify-between">
        <div className="text-xl font-bold text-indigo-600">
          It´s me <span className="text-amber-500">.</span>
        </div>
        <ul className="flex items-center space-x-5">
          <li className="text-indigo-600 hover:text-gray-300">
            <Link to="Home" smooth={true} duration={1000}>
              Home
            </Link>
          </li>
          <li className="text-indigo-600 hover:text-gray-300">
            <Link to="About" smooth={true} duration={1000}>
              About Me
            </Link>
          </li>
          <li className="text-indigo-600 hover:text-gray-300">
            <Link to="Skills" smooth={true} duration={1000}>
              Skills
            </Link>
          </li>
          <li className="text-indigo-600 hover:text-gray-300">
            <Link to="Projects" smooth={true} duration={1000}>
              Projects
            </Link>
          </li>

          <li className="text-indigo-600 hover:text-gray-300">
            <Link to="Experience" smooth={true} duration={1000}>
              Experience
            </Link>
          </li>
          <li>
            <button className="rounded-full border border-white bg-yellow-500 px-4 py-2 transition duration-300 hover:bg-white hover:text-gray-900">
              <Link to="Contact" smooth={true} duration={1000}>
                Contact
              </Link>
            </button>
          </li>
        </ul>
      </Container>
    </header>
  )
}

export default Header
