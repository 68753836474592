import Container from './Container'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'

const contentStyle = { backgroundColor: 'white', boxShadow: 'none' }

const Experience = () => {
  return (
    <Container className="bg-background py-20">
      <p className="m-10 text-center text-xs text-indigo-900 md:text-sm">
        Formação e experiência profissional
      </p>

      <VerticalTimeline
        layout="1-column"
        lineColor="white"
        className="w-full max-w-max "
      >
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="fev 2022 - até o momento · 1 ano 6 meses"
          iconStyle={{
            width: '32px',
            height: '32px',
            boxShadow: 'none',
            accentColor: '#4F46E5',
            marginLeft: '4px',
            backgroundColor: '#4F46E5',
          }}
          contentStyle={{
            backgroundColor: contentStyle.backgroundColor,
            boxShadow: contentStyle.boxShadow,
          }}
        >
          <h3 className="vertical-timeline-element-title">
            Desenvolvedor de Software
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            São Miguel - RN
          </h4>
          <p>
            Programador back-end com experiência em: JavaScript, Node.js,
            Nest.js, TypeScript e PostgreSQL, TypeORM, Prisma, Docker, Git,
            GitHub e Websockets.
          </p>
          <p>EVOCORP</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="out 2021 - fev 2022 · 5 meses"
          iconStyle={{
            width: '32px',
            height: '32px',
            boxShadow: 'none',
            accentColor: '#4F46E5',
            marginLeft: '4px',
            backgroundColor: 'white',
          }}
          position="center"
          contentStyle={{
            backgroundColor: contentStyle.backgroundColor,
            boxShadow: contentStyle.boxShadow,
          }}
        >
          <h3 className="vertical-timeline-element-title">Estagiário</h3>
          <h4 className="vertical-timeline-element-subtitle">
            São Miguel - RN
          </h4>
          <p>
            Técnico em Informática - Manutenção de computadores, resolução de
            problemas.
          </p>
          <p>WeC Digital</p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date="fev 2019 - dez 2021 · 2 anos 11 meses"
          iconStyle={{
            width: '32px',
            height: '32px',
            boxShadow: 'none',
            marginLeft: '4px',
            backgroundColor: 'white',
          }}
          contentStyle={{
            backgroundColor: contentStyle.backgroundColor,
            boxShadow: contentStyle.boxShadow,
          }}
        >
          <h3 className="vertical-timeline-element-title">Estudante</h3>
          <h4 className="vertical-timeline-element-subtitle">Pereiro - CE</h4>
          <p>Técnico em Informática - EEEP Maria Célia Pinheiro Falcão</p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </Container>
  )
}

export default Experience
