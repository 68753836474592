import Container from './Container'
import Me from '../assets/images/me.jpeg'

// itens-center flex justify-evenly gap-24

const About = () => {
  return (
    <div className="py-18 bg-white px-4 md:py-20">
      <Container>
        <div className=" flex flex-col items-center  p-4 md:flex-row md:justify-evenly">
          <div className="my-4 flex h-52 w-48 transform items-center justify-center rounded-lg border-2 border-indigo-50 border-y-indigo-500/25 border-r-indigo-500 shadow-2xl  md:h-80 md:w-72 md:-rotate-12">
            <img
              src={Me}
              alt="Foto"
              className="h-48 w-44 transform rounded-lg md:h-72 md:w-64  "
            />
          </div>
          <div className=" m-2 ml-0 flex flex-col text-center md:ml-7 md:text-left">
            <p className="p-2 text-xs text-indigo-900  md:text-left md:text-sm">
              Sobre mim
            </p>

            <p className="mb-4 text-base font-bold text-indigo-900 md:text-2xl">
              Estou aberto a oportunidades desafiadoras no <br /> campo do
              desenvolvimento back-end. <br /> Onde posso contribuir com minha
              experiência e entusiasmo!
            </p>
            <p className="mt-2 text-xs text-gray-500  md:text-base">
              Entrei muito jovem no mundo da tecnologia, pude adquirir diversos
              aprendizados ao longo da minha jornada. <br /> Tenho 2 anos de
              aprimoramento e construção da minha base na programação. <br />{' '}
              Alem disso possuo 1 ano de experiência profissional na área de
              desenvolvimento back-end, <br /> o que me proporcionou uma visão
              sólida sobre as melhores práticas e padrões de desenvolvimento.{' '}
              <br /> Além disso, sou um profissional colaborativo e com
              habilidades excepcionais de trabalho em equipe.
              <br /> Acredito que a comunicação efetiva e a colaboração são
              fundamentais para alcançar resultados excepcionais.
            </p>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default About
