import Container from './Container'
import quotify from '../assets/images/quotify.svg'

const Projects = () => {
  return (
    <div className=" bg-white py-10">
      <Container>
        <div className="md:mt-18 m-2 grid grid-flow-col grid-rows-2  md:grid-rows-1  md:gap-8">
          <div className="border-96 flex-col items-center justify-center bg-white shadow-none ">
            <p className="m-1 text-xs text-indigo-900 md:text-sm">
              Meus Projetos
            </p>
            <div className="m-1 flex ">
              <p className="mb-4  whitespace-normal text-2xl font-bold text-indigo-900 md:text-4xl">
                Veja alguns dos meus projetos mais recentes!
              </p>
            </div>

            <div className="m-3 my-2 flex md:m-1">
              <p className="text-xs text-gray-500 md:text-base">
                Como desenvolvedor, eu sempre priorizo boas práticas para
                garantir a eficiência, segurança e manutenibilidade como
                escrever código limpo e legível, utilizar padrões de design,
                considerar a segurança em todas as etapas do desenvolvimento,
                otimizar o desempenho do código, criar uma documentação clara e
                completa, e acompanhar as tendências e tecnologias relevantes.
              </p>
            </div>

            <a
              href="https://github.com/LUC4SNUN3S"
              target="_blank"
              className=" m-1 text-xs text-indigo-600 underline md:text-sm "
              rel="noreferrer"
            >
              Veja mais Projetos
            </a>
          </div>
          <div className="flex h-64 flex-row items-center justify-center gap-8  px-1  md:h-auto ">
            <div className=" hover:default: flex h-40  w-40 items-center justify-center  rounded-2xl bg-white shadow-xl transition-all duration-300 md:h-96 md:w-96 md:hover:-rotate-3 md:hover:scale-105">
              <a
                href="https://quotify.thelucasdev.cloud"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex h-36 w-36  items-center justify-center font-bold text-[#0F172A] md:h-80 md:w-80">
                  <img className="w-72" src={quotify} alt="" />
                </div>
              </a>
            </div>

            {/*  */}

            <div className="hover:default: flex h-40 w-40 items-center justify-center rounded-2xl  shadow-xl transition-all duration-300 md:h-96 md:w-96 md:hover:-rotate-3 md:hover:scale-105 ">
              <div className="relative flex h-36 w-36 flex-col justify-center text-2xl font-bold text-[#0F172A] md:h-80 md:w-80 md:text-6xl">
                SpeakEasy
                <div
                  className=" absolute bottom-9 right-1 flex h-5 w-[84px] -rotate-[6deg] items-center justify-center 
                  rounded-full bg-indigo-900 px-5 text-[10px] font-semibold text-white md:bottom-[72px] md:right-8 md:h-12 md:w-28
                  md:px-4 md:text-sm"
                >
                  em breve
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </Container>
    </div>
  )
}
export default Projects
