import Container from './Container'
import instagram from '../assets/icons/instagram.svg'
import linkedin from '../assets/icons/linkedin.svg'
import github from '../assets/icons/github.svg'
import wpp from '../assets/icons/wpp.svg'
import mention from '../assets/images/mention.gif'

const Contact = () => {
  return (
    <div className="bg-white py-24">
      <Container>
        <div className="flex flex-col items-center justify-around gap-40 md:flex-row">
          <div className="my-5 h-96 w-96">
            <p className="my-2 text-center text-xs text-indigo-600 md:text-left md:text-sm">
              Entre em contato
            </p>

            <p className="mb-4 whitespace-normal text-2xl font-bold text-indigo-900 md:text-4xl">
              Gostou do meu trabalho? Por favor, deixe uma mensagem
            </p>

            <p className="mt-4 p-4 text-sm text-gray-500 md:p-0  md:text-lg">
              Entre em contato e me diga como posso ajudar. Entrarei em contato
              o mais breve possível.{' '}
            </p>

            <div className="my-6 flex flex-col">
              <p className="mb-1 whitespace-normal text-xs font-bold text-indigo-900 md:text-sm">
                Telefone
              </p>
              <p className="mb-5 text-xs text-gray-500 md:text-sm">
                (84) 9 9108-7045
              </p>

              <p className="whitespace-normal text-xs font-bold text-indigo-900 md:text-sm">
                E-mail
              </p>
              <p className="text-xs text-gray-500 md:text-sm">
                lucasmartins2k21@gmail.com
              </p>
            </div>

            <div className="flex justify-center gap-4  md:justify-stretch">
              <a
                href="https://github.com/LUC4SNUN3S"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex h-14 w-14 items-center justify-center rounded-full bg-background transition-all duration-700 hover:scale-125">
                  <img src={github} alt="" />
                </div>
              </a>
              <a
                href="https://www.linkedin.com/in/lucas-nunes-052572208/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex h-14 w-14 items-center justify-center rounded-full bg-background transition-all duration-700 hover:scale-125">
                  <img src={linkedin} alt="" />
                </div>
              </a>

              <a
                href="https://www.instagram.com/lucas_nunes.m/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex h-14 w-14 items-center justify-center rounded-full bg-background transition-all duration-700 hover:scale-125">
                  <img src={instagram} alt="" />
                </div>
              </a>

              <a
                href="https://encurtaki.me/lM9Jl"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex h-14 w-14 items-center justify-center rounded-full bg-background transition-all duration-700 hover:scale-125">
                  <img src={wpp} alt="" />
                </div>
              </a>
            </div>
          </div>

          <div className="hidden md:block md:h-96 md:w-96">
            <img src={mention} alt="" />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Contact
