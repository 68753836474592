import { Element } from 'react-scroll'
import About from './components/About'
import Contact from './components/Contact'
import Experience from './components/Experience'
import Footer from './components/Footer'
import Header from './components/Header'
import Home from './components/Home'
import Projects from './components/Projects'
import Skills from './components/Skills'

function App() {
  return (
    <div>
      <Header />
      <Element name="Home">
        <Home />
      </Element>

      <Element name="About">
        <About />
      </Element>

      <Element name="Skills">
        <Skills />
      </Element>

      <Element name="Projects">
        <Projects />
      </Element>

      <Element name="Experience">
        <Experience />
      </Element>

      <Element name="Contact">
        <Contact />
      </Element>
      <Footer />
    </div>
  )
}

export default App
