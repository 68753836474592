import Container from './Container'

const Footer = () => {
  return (
    <Container>
      <div className="flex items-center justify-center">
        <div className="border-red flex h-14 w-[2000px] items-center justify-evenly md:justify-between">
          <div className="flex flex-col text-center">
            <p className="text-[8px] text-gray-500 md:text-xs">
              Design -{' '}
              <a
                target="_blank"
                href="https://github.com/fernandes-vinicius"
                rel="noreferrer"
              >
                <span className="underline">Vinicius fernandes</span>
              </a>
            </p>
          </div>
          <p className="text-xl font-bold text-indigo-600 md:text-3xl">
            It´s me <span className="text-amber-500">.</span>
          </p>
          <div className="flex flex-col items-end">
            <p className="text-[8px] text-gray-500 md:text-xs">
              Construção -{' '}
              <a
                target="_blank"
                href="https://github.com/fernandes-vinicius"
                rel="noreferrer"
              >
                <span className="underline">Lucas Nunes</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default Footer
