import Container from './Container'

import typescript from '../assets/images/typescript.svg'
import javascript from '../assets/images/javascript.svg'
import nodeJs from '../assets/images/node.svg'
import nestJs from '../assets/images/nest.svg'
import typeOrm from '../assets/images/typeorm.png'
import docker from '../assets/images/docker.svg'
import postgres from '../assets/images/postgres.svg'
import socket from '../assets/images/socket.svg'
import prisma from '../assets/images/prisma.svg'
import Card from './Card'
import git from '../assets/images/git.svg'

const Skills = () => {
  return (
    <div className="  bg-background py-20">
      <Container>
        <div className="m-7 flex flex-col items-center gap-10  text-center md:flex-row md:gap-24 md:text-left">
          <div>
            <p className="m-1 text-xs text-indigo-900 md:text-sm">
              Minhas Skills
            </p>
            <p className="mb-4  whitespace-normal text-2xl font-bold text-indigo-900">
              O que minhas habilidades com programação incluem?
            </p>
            <p className="text-sm text-gray-500 md:text-base">
              Minhas habilidades técnicas abrangem JavaScript, Node.js, Nest.js,
              TypeScript, TypeOrm, Prisma, Docker e PostgreSQL, permitindo-me
              desenvolver aplicações escaláveis e de alto desempenho.
            </p>
          </div>
          <div className="grid grid-flow-col grid-rows-2 gap-4 md:gap-12">
            <Card>
              <img className="w-8 rounded-md md:w-14" src={typescript} alt="" />
            </Card>
            <Card>
              <img className="w-8 rounded-md md:w-14" src={javascript} alt="" />
            </Card>

            <Card>
              <img className="w-8 md:w-14" src={nodeJs} alt="" />
            </Card>

            <Card>
              <img className="w-8 md:w-14" src={nestJs} alt="" />
            </Card>

            <Card>
              <img className="w-8 md:w-14" src={typeOrm} alt="" />
            </Card>

            <Card>
              <img className="w-8 md:w-14" src={prisma} alt="" />
            </Card>

            <Card>
              <img className="w-8 md:w-14" src={docker} alt="" />
            </Card>

            <Card>
              <img className="w-8 md:w-14" src={postgres} alt="" />
            </Card>

            <Card>
              <img className="w-8 md:w-14" src={socket} alt="" />
            </Card>

            <Card>
              <img className="w-8 md:w-14" src={git} alt="" />
            </Card>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Skills
