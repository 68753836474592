import Container from './Container'
import { Link } from 'react-scroll'

const Home = () => {
  return (
    <section className=" bg-background px-4 py-20">
      <Container>
        <p className="text-sm text-indigo-900">Olá, meu nome é</p>
        <h1 className="md:text-textHeader mt-4 text-5xl font-bold text-indigo-600 md:text-8xl">
          Lucas Nunes
        </h1>
        <p className="text-4xl font-bold text-indigo-900 md:mt-2 md:text-8xl">
          Backend Software Developer
        </p>
        <p className="text-md mt-4 text-gray-500  md:text-lg">
          Sou um desenvolvedor back-end altamente motivado e dedicado, com
          experiência sólida <br /> na criação de soluções robustas e
          eficientes.
        </p>

        <Link to="Contact" smooth={true} duration={1000}>
          <button className="mt-8 rounded-full border border-white bg-yellow-500 px-4 py-2 text-white transition duration-300 hover:bg-white hover:text-gray-900">
            Fale Comigo
          </button>
        </Link>
        <Link to="Projects" smooth={true} duration={1000}>
          <a className="ml-4 text-indigo-600 underline">Conheça meu trabalho</a>
        </Link>
      </Container>
    </section>
  )
}

export default Home
