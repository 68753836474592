import React from 'react'

import clsx from 'clsx'

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {}

const Card = ({ children, className, ...rest }: ContainerProps) => {
  return (
    <div
      {...rest}
      className={clsx(
        'hover:default: flex h-10 w-10 items-center justify-center rounded-lg bg-white shadow-2xl transition-all duration-700 md:h-20 md:w-20 md:hover:scale-125',
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Card
